<template>
  <div>
    <!-- 封面图 -->
    <div class="banner" :style="postCover">
    </div>
    <!-- 内容 -->
    <v-row>
      <el-card class="edit-card">
        <div class="title">{{ this.$route.name }}</div>
        <!-- 文章标题 -->
        <div class="article-title-container" style="max-width: 50%;">
          <el-input v-model="article.articleTitle" size="mini" placeholder="输入文章标题" />
          <el-button type="primary" size="mini" @click="saveArticleDraft"
            v-if="article.id == null || article.status == 3" style="margin-left:10px">
            保存草稿
          </el-button>
          <el-button type="success" size="mini" @click="openModel" style="margin-left:10px">
            发布文章
          </el-button>
        </div>
        <!-- 文章内容 -->
        <mavon-editor ref="md" v-model="article.articleContent" 
        @imgAdd="uploadImg" style="height:calc(100vh - 220px); width: 100%"/>
        <!-- 添加文章对话框 -->
        <el-dialog :visible.sync="addOrEdit" width="40%" top="3vh">
          <div class="dialog-title-container" slot="title">
            发布文章
          </div>
          <!-- 文章数据 -->
          <el-form label-width="80px" size="mini" :model="article">
            <!-- 文章分类 -->
          <el-form-item label="文章分类">
            <el-select v-model="article.categoryName" placeholder="请选择文章分类">
              <el-option v-for="item of categoryList" :key="item.id" :label="item.categoryName" :value="item.id" ></el-option>
            </el-select>
          </el-form-item>
            <!-- 文章标签 -->
            <el-form-item label="文章标签" >
              <el-tag v-for="(item, index) of article.tagNameList" :key="index" style="margin:0 1rem 0 0" :closable="true"
                @close="removeTag(item)">
                {{ item }}
              </el-tag>
              <!-- 标签选项 -->
              <el-popover placement="bottom-start" width="460" trigger="click" v-if="article.tagNameList.length < 3">
                <div class="popover-title">标签</div>
                <!-- 搜索框 -->
                <el-autocomplete style="width:100%" v-model="tagName" :fetch-suggestions="searchTags"
                  placeholder="请输入标签名搜索，enter可添加自定义标签" :trigger-on-focus="false" @keyup.enter.native="saveTag"
                  @select="handleSelectTag">
                  <template slot-scope="{ item }">
                    <div>{{ item.tagName }}</div>
                  </template>
                </el-autocomplete>
                <!-- 标签 -->
                <div class="popover-container">
                  <div style="margin-bottom:1rem">添加标签</div>
                  <el-tag v-for="(item, index) of tagList" :key="index" :class="tagClass(item)" @click="addTag(item)">
                    {{ item.tagName }}
                  </el-tag>
                </div>
                <el-button type="primary" plain slot="reference" size="small">
                  +
                </el-button>
              </el-popover>
            </el-form-item>
            <el-form-item label="文章类型">
              <el-select v-model="article.type" placeholder="请选择类型">
                <el-option v-for="item in typeList" :key="item.type" :label="item.desc" :value="item.type" />
              </el-select>
            </el-form-item>
            <el-form-item label="分享资源">
              <el-select v-model="article.resourceShareId" placeholder="请选择资源">
                <el-option v-for="item in shareList" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <!-- 文章类型 -->
            <el-form-item label="原文地址" v-if="article.type != 1">
              <el-input v-model="article.originalUrl" placeholder="请填写原文链接" />
            </el-form-item>
            <el-form-item label="上传封面">
              <el-upload class="upload-cover" drag action="/api/admin/articles/images" multiple
                :before-upload="beforeUpload" :on-success="uploadCover"  >
                <i class="el-icon-upload" v-if="article.articleCover == ''" />
                <div class="el-upload__text" v-if="article.articleCover == ''">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <img v-else :src="article.articleCover" />
              </el-upload>
            </el-form-item>
            <el-form-item label="置顶">
              <el-switch v-model="article.isTop" active-color="#13ce66" inactive-color="#F4F4F5" :active-value="1"
                :inactive-value="0" />
            </el-form-item>
            <el-form-item label="发布形式">
              <el-radio-group v-model="article.status">
                <el-radio :label="1" default-checked>公开</el-radio>
                <el-radio :label="2">私密</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="addOrEdit = false">取 消</el-button>
            <el-button type="danger" @click="saveOrUpdateArticle">
              发 表
            </el-button>
          </div>
        </el-dialog>
      </el-card>
    </v-row>

  </div>
</template>

<script>

import * as imageConversion from "image-conversion";
import Vue from "vue";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { Message } from 'element-ui';
Vue.use(ElementUI);
Vue.use(mavonEditor);
export default {
  created() {
      //当前用户的消息才回
    let loginInfo = localStorage.getItem("userinfo");
    if (loginInfo == undefined) {
      Message.error({
              title: "失败",
              message: "未登录"
            });
      return;
    }
    const path = this.$route.path;
    const arr = path.split("/");
    const articleId = arr[2];
    if (articleId) {
      this.axios.get("/api/admin/articles/" + articleId).then(({ data }) => {
        this.article = data.data;
      });
    } else {
      const article = sessionStorage.getItem("article");
      if (article) {
        this.article = JSON.parse(article);
      }
    }
    //查询当前用户的所有资源 
    this.axios.post("/api/share-resource/resource", {
              current: this.current,
              size: this.size,
              status: this.status,
            }).then(({ data }) => {
        this.shareList = data.data.recordList;
      });
  },
  destroyed() {
    //文章自动保存功能
    this.autoSaveArticle();
  },
  data: function () {
    return {
      addOrEdit: false,
      autoSave: true,
      categoryName: "",
      tagName: "",
      categoryList: [],
      tagList: [],
      typeList: [
        {
          type: 1,
          desc: "原创"
        },
        {
          type: 2,
          desc: "转载"
        },
        {
          type: 3,
          desc: "翻译"
        }
      ],
      shareList: [],
      article: {
        id: null,
        articleTitle: "",
        articleContent: "",
        articleCover: "",
        categoryName: null,
        resourceShareId: 0,
        tagNameList: [],
        originalUrl: "",
        isTop: 0,
        type: 1,
        status: 1
      }
    };
  },
  methods: {
    listCategories() {
      this.axios.get("/api/admin/categories/search").then(({ data }) => {
        this.categoryList = data.data;
      });
    },
    listTags() {
      this.axios.get("/api/admin/tags/search").then(({ data }) => {
        this.tagList = data.data;
      });
    },
    openModel() {
      if (this.article.articleTitle.trim() == "") {
        this.$message.error("文章标题不能为空");
        return false;
      }
      if (this.article.articleContent.trim() == "") {
        this.$message.error("文章内容不能为空");
        return false;
      }
      this.listCategories();
      this.listTags();
      this.addOrEdit = true;
    },
    uploadCover(response) {
      this.article.articleCover = response.data;
    },
    beforeUpload(file) {
      return new Promise(resolve => {
        if (file.size / 1024 < this.config.UPLOAD_SIZE) {
          resolve(file);
        }
        // 压缩到200KB,这里的200就是要压缩的大小,可自定义
        imageConversion
          .compressAccurately(file, this.config.UPLOAD_SIZE)
          .then(res => {
            resolve(res);
          });
      });
    },
    uploadImg(pos, file) {
      var formdata = new FormData();
      if (file.size / 1024 < this.config.UPLOAD_SIZE) {
        formdata.append("file", file);
        this.axios
          .post("/api/admin/articles/images", formdata)
          .then(({ data }) => {
            this.$refs.md.$img2Url(pos, data.data);
          });
      } else {
        // 压缩到200KB,这里的200就是要压缩的大小,可自定义
        imageConversion
          .compressAccurately(file, this.config.UPLOAD_SIZE)
          .then(res => {
            formdata.append(
              "file",
              new window.File([res], file.name, { type: file.type })
            );
            this.axios
              .post("/api/admin/articles/images", formdata)
              .then(({ data }) => {
                this.$refs.md.$img2Url(pos, data.data);
              });
          });
      }
    },
    saveArticleDraft() {
      if (this.article.articleTitle.trim() == "") {
        this.$message.error("文章标题不能为空");
        return false;
      }
      if (this.article.articleContent.trim() == "") {
        this.$message.error("文章内容不能为空");
        return false;
      }
      this.article.status = 3;
      this.axios.post("/api/admin/articles", this.article).then(({ data }) => {
        if (data.flag) {
          if (this.article.id === null) {
            this.$store.commit("removeTab", "发布文章");
          } else {
            this.$store.commit("removeTab", "修改文章");
          }
          sessionStorage.removeItem("article");
          this.$router.push({ path: "/post-list" });
          this.$notify.success({
            title: "成功",
            message: "保存草稿成功"
          });
        } else {
          this.$notify.error({
            title: "失败",
            message: "保存草稿失败"
          });
        }
      });

      //关闭自动保存功能
      this.autoSave = false;
    },
    saveOrUpdateArticle() {
      if (this.article.articleTitle.trim() == "") {
        this.$message.error("文章标题不能为空");
        return false;
      }
      if (this.article.articleContent.trim() == "") {
        this.$message.error("文章内容不能为空");
        return false;
      }
      if (this.article.categoryName == null) {
        this.$message.error("文章分类不能为空");
        return false;
      }
      if (this.article.tagNameList.length == 0) {
        this.$message.error("文章标签不能为空");
        return false;
      }
      if (this.article.articleCover.trim() == "") {
        //this.$message.error("文章封面不能为空");
        //return true;
      }
      this.axios.post("/api/admin/articles", this.article).then(({ data }) => {
        if (data.flag) {
          if (this.article.id === null) {
            this.$store.commit("removeTab", "发布文章");
          } else {
            this.$store.commit("removeTab", "修改文章");
          }
          sessionStorage.removeItem("article");
          this.$router.push({ path: "/post-list" });
          this.$notify.success({
            title: "成功",
            message: data.message
          });
        } else {
          this.$notify.error({
            title: "失败",
            message: data.message
          });
        }
        this.addOrEdit = false;
      });
      //关闭自动保存功能
      this.autoSave = false;
    },
    autoSaveArticle() {
      // 自动上传文章
      if (
        this.autoSave &&
        this.article.articleTitle.trim() != "" &&
        this.article.articleContent.trim() != "" &&
        this.article.id != null
      ) {
        this.axios
          .post("/api/admin/articles", this.article)
          .then(({ data }) => {
            if (data.flag) {
              this.$notify.success({
                title: "成功",
                message: "自动保存成功"
              });
            } else {
              this.$notify.error({
                title: "失败",
                message: "自动保存失败"
              });
            }
          });
      }
      // 保存本地文章记录
      if (this.autoSave && this.article.id == null) {
        sessionStorage.setItem("article", JSON.stringify(this.article));
      }
    },
    searchCategories(keywords, cb) {
      this.axios
        .get("/api/admin/categories/search", {
          params: {
            keywords: keywords
          }
        })
        .then(({ data }) => {
          cb(data.data);
        });
    },
    handleSelectCategories(item) {
      this.addCategory({
        categoryName: item.categoryName
      });
    },
    saveCategory() {
      if (this.categoryName.trim() != "") {
        this.addCategory({
          categoryName: this.categoryName
        });
        this.categoryName = "";
      }
    },
    addCategory(item) {
      this.article.categoryName = item.categoryName;
    },
    removeCategory() {
      this.article.categoryName = null;
    },
    searchTags(keywords, cb) {
      this.axios
        .get("/api/admin/tags/search", {
          params: {
            keywords: keywords
          }
        })
        .then(({ data }) => {
          cb(data.data);
        });
    },
    handleSelectTag(item) {
      this.addTag({
        tagName: item.tagName
      });
    },
    saveTag() {
      if (this.tagName.trim() != "") {
        this.addTag({
          tagName: this.tagName
        });
        this.tagName = "";
      }
    },
    addTag(item) {
      if (this.article.tagNameList.indexOf(item.tagName) == -1) {
        this.article.tagNameList.push(item.tagName);
      }
    },
    removeTag(item) {
      const index = this.article.tagNameList.indexOf(item);
      this.article.tagNameList.splice(index, 1);
    },
  },
  computed: {
    tagClass() {
      return function (item) {
        const index = this.article.tagNameList.indexOf(item.tagName);
        return index != -1 ? "tag-item-select" : "tag-item";
      };
    },
    postCover() {
      var cover = "";
        this.$store.state.blogInfo.pageList.forEach(item => {
          if (item.pageLabel == "post") {
            cover = item.pageCover;
          }
        });
        return "background: url(" + cover + ") center center / cover no-repeat";
    },
  },
};
</script>

<style scoped>
.edit-card {
  margin: 200px auto 40px auto !important;
  width: 90%;
  min-width: 500px;
  min-height: 66px;
}

.article-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-top: 2.25rem;
}



.tag-item {
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.tag-item-select {
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: not-allowed;
  color: #ccccd8 !important;
}

.category-item {
  cursor: pointer;
  padding: 0.6rem 0.5rem;
}

.category-item:hover {
  background-color: #f0f9eb;
  color: #67c23a;
}

.popover-title {
  margin-bottom: 1rem;
  text-align: center;
}

.popover-container {
  margin-top: 1rem;
  height: 220px;
  overflow-y: auto;
}
img {
  display: block;
  margin: 0 auto;
}
</style>
